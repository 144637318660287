import {
	ATLASSIAN_CONSENT_FALLBACK_COOKIE_KEY,
	DEFAULT_PREFERENCES,
	LATEST_SCHEMA_KEY,
} from '../../../constants';
import {
	type ConsentPreference,
	type ConsentPreferenceV003,
	ContextualConsentProperty,
	PreferenceCategory,
} from '../../../types';
import { getConsentTokenForSchemaVersion } from '../get-consent-token-for-schema-version';
import { getCookie } from '../get-cookie';
import { setConsentTokenFallbackCookie } from '../set-required-consent-cookies';
import { unpackUserPreferencesCookie } from '../transformer';

const pruneLegacyProperties = (updatedPreferences: ConsentPreference): ConsentPreference => {
	// Strips any possible V002/legacy properties from the preferences by excluding everything but the core prefs
	return {
		[PreferenceCategory.StrictlyNecessary]:
			updatedPreferences[PreferenceCategory.StrictlyNecessary],
		[PreferenceCategory.Functional]: updatedPreferences[PreferenceCategory.Functional],
		[PreferenceCategory.Analytics]: updatedPreferences[PreferenceCategory.Analytics],
		[PreferenceCategory.Marketing]: updatedPreferences[PreferenceCategory.Marketing],
		[PreferenceCategory.Unknown]: updatedPreferences[PreferenceCategory.Unknown],
	};
};

const setContextualConsentProperties = (
	consentPreferences: ConsentPreference,
	usingPrefetchedData?: boolean,
) => {
	// When merging during this phase, retain the "unauthed/authed" context of the preferences
	const existingUserAuthenticatedState = (consentPreferences as ConsentPreferenceV003)?.[
		ContextualConsentProperty.UserIsAuthenticated
	];

	const existingConsentHubUnavailableState = !!(consentPreferences as ConsentPreferenceV003)?.[
		ContextualConsentProperty.ConsentDataUnavailable
	];

	// When processing a legacy token, UserIsAuthenticated will be missing, so we need to backfill it with context from ConsentHubUnavailable
	const existingOrBackfilledAuthState =
		existingUserAuthenticatedState === undefined
			? !existingConsentHubUnavailableState
			: existingUserAuthenticatedState;

	// Overwriting this flag while merging here gets us into a situation where a prefetched/cached, default cookie is just being set in the system,
	// but we mark the consents as "genuine", when they haven't actually been saved.
	// Doing this would have negative downstream effects, as we invoke logic dependent on if a token is a default or not

	const existingDefaultState = !!(consentPreferences as ConsentPreferenceV003)?.[
		ContextualConsentProperty.ConsentsAreDefault
	];

	const consentsAreDefault = usingPrefetchedData ? existingDefaultState : false;

	return {
		[ContextualConsentProperty.ConsentsAreDefault]: consentsAreDefault,
		[ContextualConsentProperty.UserIsAuthenticated]: existingOrBackfilledAuthState,
		[ContextualConsentProperty.ConsentDataUnavailable]: existingConsentHubUnavailableState,
	};
};

/**
  Takes any existing token data from the ConsentToken cookie, merges it with any preferences that are being updated
  via saving in the the UI, and returns a blended set of preferences with any missing properties backfilled

  @param updatedPreferences: Contains user preferences which we're blending into a final consent state before saving
    coming from the UI may only have the cookie consent bits, rather than extra contextual bits, so backfill them
  @param usingPrefetchedData (Optional): Flag denoting that this function execution is happening during the initial setup phase
    and we have been provided prefetched consent data.
    If executed while using prefetched data, we don't want to alter any "ConsentsAreDefault" flags, as this iteration is *not*
    actually a "save" (when we'd want to change the flag), but instead a "set initial system cookies based off prefetched data" flow.
  @returns ConsentPreference
*/
export const mergeConsentUpdates = (
	updatedPreferences: ConsentPreference,
	usingPrefetchedData?: boolean,
): ConsentPreference => {
	// DEFAULT_PREFERENCES now gives us "CH unavailable" and "is default" bits

	const existingPrefs = getConsentTokenForSchemaVersion(LATEST_SCHEMA_KEY);

	if (existingPrefs) {
		// Unpacking a V2 should now result in a V3 token anyway, but ensure we have proper bits
		const unpackedExistingPrefs = unpackUserPreferencesCookie(
			existingPrefs,
		) as ConsentPreferenceV003;
		// Capture any missed options (shouldn't have any if we have existing consent token), and
		// ensure we write the bit dictating that these consents are no longer defaults since they're being saved
		if (unpackedExistingPrefs) {
			return {
				...DEFAULT_PREFERENCES,
				...unpackedExistingPrefs,
				...pruneLegacyProperties(updatedPreferences),
				...setContextualConsentProperties(unpackedExistingPrefs, usingPrefetchedData),
			};
		}

		return {
			...DEFAULT_PREFERENCES,
			...pruneLegacyProperties(updatedPreferences),
		};
	}
	// If we don't have existing prefs, this is likely a first time save for this domain
	// Ensure we note this is a successful, genuine set of prefs by updating the contextual properties as needed

	return {
		...DEFAULT_PREFERENCES,
		...pruneLegacyProperties(updatedPreferences),
		...setContextualConsentProperties(updatedPreferences, usingPrefetchedData),
	};
};

export const onlySetFallbackIfDifferent = (consentToken: string) => {
	// Diff the token we're trying to set vs. any existing fallback, and only set a new fallback if the value has changed.
	// A difference would mean the user made a change since their original fallback was stored and we should update.
	// Otherwise, we would be unintentionally overriding the "1 year" expiration date the fallback token tracks.
	const existingFallbackToken = getCookie(ATLASSIAN_CONSENT_FALLBACK_COOKIE_KEY);

	const tokenDiffersFromOldFallback = existingFallbackToken !== consentToken;
	if (!existingFallbackToken || tokenDiffersFromOldFallback) {
		setConsentTokenFallbackCookie(consentToken);
	}
};
